import React from "react";
import LayoutIframe from "../layouts/LayoutIframe";
import LayoutLocalized from "../layouts/LayoutLocalized";
import Enterprise from "../components/Paywalls/Enterprise";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { PageContext } from "../store/context/page-context";
import { graphql } from "gatsby";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import Seo from "../components/Seo";
import useEventFromExt from "../hooks/useEventFromExt";

const ProAiEnterpriseTemplate = ({ pageContext }) => {
  const { isIframe } = pageContext;
  const { t, i18n } = useTranslation();
  const { software } = useEventFromExt();
  return (
    <PageContext.Provider
      value={{
        translate: t,
        pathname: "/pricing-pro-ai-enterprise",
        isDeMode: t("code") === "de",
        isJpMode: t("code") === "ja",
        isIframeMode: isIframe,
        software,
      }}
    >
      {isIframe ? (
        <LayoutIframe>
          <Enterprise context={pageContext} />
        </LayoutIframe>
      ) : (
        <LayoutLocalized>
          <Seo
            title={t("title")}
            description="Use AMZScout and make Amazon product research easy with accurate research tools and seller software: AMZScout PRO Chrome Extension, Product Database, Keyword Search and more. Analyze niches and find the right product to make money. Start your free trial."
            page={i18n.path}
            manifest="browserconfig.xml"
            ogImg={OgImg}
          />
          <Enterprise context={pageContext} />
        </LayoutLocalized>
      )}
    </PageContext.Provider>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "pro-ai-enterprise" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ProAiEnterpriseTemplate;
